/* Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css */
@font-face {
  font-family: "YourCustomFontFour";
  src: url('/public/kiteOne.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'YourCustomFontThree';
  src: url('/public/feelingPassionate.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'YourCustomFontTwo';
  src: url('/public/Consolas.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@keyframes enterTransition {
0% {
  transform: translate3d(0, -100%, 0) scale3d(0.5, 0.5, 0.5);
  opacity: 0;
}
100% {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  opacity: 1;
}
}

.enterAnimation {
animation: enterTransition 1s ease-out forwards;
transform-style: preserve-3d;
}

@keyframes enterFromRight {
0% {
  transform: translate3d(100%, 0, 0) scale3d(1, 1, 1);
  opacity: 0;
}
100% {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  opacity: 1;
}
}

.enterAnimationFromRight {
animation: enterFromRight 1s ease-out forwards;
transform-style: preserve-3d;
}

@keyframes rotateAnimation {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}

.rotate {
animation: rotateAnimation 5s linear infinite;
}

@keyframes scaleAnimation {
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.1);
}
100% {
  transform: scale(1);
}
}

.scale {
animation: scaleAnimation 3s ease-in-out infinite;
}

@keyframes opacityAnimation {
0% {
  opacity: 1;
}
50% {
  opacity: 0.5;
}
100% {
  opacity: 1;
}
}

.opacity {
animation: opacityAnimation 3s ease-in-out infinite;
}

@keyframes gradient-x {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}

.animate-gradient-x {
background-size: 200% 200%;
animation: gradient-x 8s ease infinite;
}

@keyframes twinkle {
0%, 100% { opacity: 0; }
50% { opacity: 1; }
}

.stars-container {
position: absolute;
width: 100%;
height: 100%;
overflow: hidden;
}

.star {
position: absolute;
width: 4px;
height: 4px;
background: white;
box-shadow: 0 0 2px #fff, 0 0 5px #ff9068;
border-radius: 50%;
animation: twinkle 5s infinite ease-in-out;
}

.fade-in {
animation: fadeInAnimation ease 3s;
animation-iteration-count: 1;
animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
0% {
  opacity: 0;
  transform: translateX(-100px);
}
100% {
  opacity: 1;
  transform: translateX(0);
}
}

.hidden {
opacity: 0;
}

.semi-transparent-bg {
background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
padding: 10px; /* or as needed */
}

.neon-glow {
color: #fff;
text-shadow:
  0 0 5px #ff0000,
  0 0 10px #ff0000,
  0 0 20px #ff0000,
  0 0 40px #ff3300,
  0 0 80px #ff3300,
  0 0 100px #ff3300,
  0 0 150px #ff3300;
}

.neon-glow-subtext {
  color: #fff;
  text-shadow:
    0 0 3px #ff00ff,
    0 0 6px #ff00ff,
    0 0 9px #ff33ff,
    0 0 12px #ff33ff;
  position: relative;
  overflow: hidden;
}

/* This will hide the scrollbar for the AboutBigDiv element, but still allow scrolling */
#AboutBigDiv {
overflow-y: auto; /* Enables vertical scrolling */
scrollbar-width: none; /* Hides scrollbar for Firefox */
-ms-overflow-style: none; /* Hides scrollbar for Internet Explorer 10+ */
}

/* Hides scrollbar for Chrome, Safari and Opera */
#AboutBigDiv::-webkit-scrollbar {
display: none;
}

/* Red shadow */
.shadow-red {
box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); /* Adjust the color and opacity as needed */
}

/* Black shadow */
.shadow-black {
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
}

/* Timeline */
.timeline-carousel {
  position: relative;
  margin: 0 auto;
  max-width: 1000px; /* Adjust as needed */
}

.timeline-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.timeline-navigation button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.timeline-item {
  text-align: center;
  padding: 20px;
}

.timeline-date {
  margin-bottom: 5px;
  font-weight: bold;
}

.timeline-content {
  margin-top: 20px;
}

.timeline-content h3 {
  font-size: 18px;
  margin-bottom: 20px;
}

.timeline-content p {
  font-size: 16px;
  margin: 10px 0;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.timeline-item {
  animation: fadeIn 2s ease-in-out;
}

.timeline-content h3, .timeline-content p {
  transition: color 0.5s ease-in-out;
}

.timeline-content:hover h3, .timeline-content:hover p {
  color: #ffc0cb; 
}

.image-background {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust as needed */
  background-size: cover;
  background-position: center;
  border: '2px solid #fff';
}
.overlay-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 5px;
}

/* LayoutGridDemo.css */
.jigsaw-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px; /* Adjust gap between images */
}

.jigsaw-item img {
  display: block;
  width: 100%;
  height: 100%;
}

.jigsaw-item {
  /* Example sizes, adjust as necessary */
}

/* row 1 */
.jigsaw-item-1 { width: 31%; }
.jigsaw-item-2 { width: 31%; }
.jigsaw-item-3 { width: 31%; }
/* row 2 */
.jigsaw-item-4 { width: 25%; }
.jigsaw-item-5 { width: 60%; }
/* row 3 */
.jigsaw-item-6 { width: 35%; }
.jigsaw-item-7 { width: 35%; }
.jigsaw-item-8 { width: 20%; }
/* row 4 */
.jigsaw-item-9 { width: 22%; }
.jigsaw-item-10 { width: 40%; }
/* Add more item-specific styles as needed */

.modal-enter {
  opacity: 0;
  transform: scale(0.9);
}

.modal-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  opacity: 1;
}

.modal-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.text-shadow {
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.leading-tight {
  line-height: 1.25; 
}
.leading-relaxed {
  line-height: 1.5; 
}
.spacing {
  letter-spacing: 0.5px; 
}

/* BTS.css */
.tv-frame {
  width: 40%;
  /* padding-top: 30%;  */
  margin: 20px 0;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  border: 5px solid #333;
  background: #000;
}

.tv-frame:before {
  content: '';
  position: absolute;
  top: -2.5%;
  left: -1%;
  right: -1%;
  bottom: -2.5%;
  border: 10px solid #b40505;
  border-radius: 15px;
}

/* .video-slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bts-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px; 
} */

.button-style-5 {
  background-color: #FF4757;
  border-radius: 50%;
  border: none;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 5px #D63031; /* Shadow to create depth */
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.button-style-5:active {
  box-shadow: 0 2px #D63031; /* Adjust shadow to simulate press down */
  transform: translateY(3px); /* Move down to simulate press */
}

.button-style-5:hover {
  transform: scale(1.1); /* Enlarge on hover */
  border-color: #FFD700; /* Change border color on hover */
}












